@import './variables.scss';
@import './reset.scss';
@import './mixins.scss';
@import './components/index.scss';

html,
body,
#root,
canvas {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #272730; // Dark mode standard
  color: #f7f7f7; // Dark mode standard
}

@include themeColors(); // Load CSS color variables

.App {
  @include bodyText();
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh) * 100); // Dynamic vh unit
  // position: fixed;
  background-color: var(--main-background);
  color: var(--main-typography);
  padding: 1rem;
  overflow-x: hidden;

  @include transitionColors();
}

h1 {
  // color: f7f7f7;
  @include titleText();
  @include h1Title();
  @include transitionColors();
}
h2 {
  // color: f7f7f7;
  @include titleText();
  @include h2Title();
  @include transitionColors();
}
h3 {
  // color: f7f7f7;
  @include titleText();
  @include h3Title();
  @include transitionColors();
}
h4 {
  // color: f7f7f7;
  @include titleText();
  @include h4Title();
  @include transitionColors();
}

p,
i,
strong,
a,
em,
.fa,
.fal {
  color: var(--main-typography);
  @include transitionColors();
}

/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: var(--main-typography) transparent;
  overscroll-behavior: none;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--main-typography);
  border-radius: 0;
}

.main-columns {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
