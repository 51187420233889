.story {

  h2 {
    margin-bottom: 1rem;
  }
  .story-element {
    padding: 0 0 1rem;

    &.text {
      transition: opacity 0.5s ease;
      &.seen {
        opacity: 0.5;
      }
    }

    &.choice {
      .choice-button {
        padding: 0;
        color: inherit;
        font-family: inherit;
        font-size: inherit;
        font-weight: 500;
        text-decoration: underline;
      }
    }
  }

  .scroll-marker {
    max-height: 0;
    width: 100%;
  }
}

.debug {
  position: fixed;
  right: 1rem;
  top: 6.5rem;
  bottom: 0;
  width: calc(50vw - 4rem);
}
